<template>
    <uw-content title="我的项目" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.status == null ? 'primary' : 'text'" @click="search.status = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.status == 1 ? 'primary' : 'text'" @click="search.status = 1; TableSearch()">进行中</el-button>
            <el-button size="mini" :type="search.status == 5 ? 'primary' : 'text'" @click="search.status = 5; TableSearch()">待验收</el-button>
            <el-button size="mini" :type="search.status == 4 ? 'primary' : 'text'" @click="search.status = 4; TableSearch()">已关闭</el-button>
            <el-button size="mini" :type="search.status == 2 ? 'warning' : 'text'" @click="search.status = 2; TableSearch()">已暂停</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.projectsCreate.open()" >新的项目</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <el-select @change="TableSearch" v-model="search.is_overdue" size="mini" clearable placeholder="是否延期">
                    <el-option label="未延期" :value=0></el-option>
                    <el-option label="已延期" :value=1></el-option>
                </el-select>

                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.number"  clearable  size="mini" placeholder="请输入项目编号"></el-input>

                <el-input @input="$lodash.debounce(TableSearch, 500)" v-model="search.name"  clearable  size="mini" placeholder="请输入项目名称"></el-input>

                <el-select @change="TableSearch" v-model="search.role" size="mini" clearable placeholder="我的身份">
                    <el-option label="项目经理" value="leader"></el-option>
                    <el-option label="项目销售" value="sale"></el-option>
                    <el-option label="项目成员" value="helper"></el-option>
                    <el-option label="管理员(创建者)" value="admin"></el-option>
                </el-select>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">
                                    
                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 项目编号 -->
                    <vxe-column width="150" field="number" title="项目编号" sortable>
                        <template #default="{ row }">
                            <el-link @click="$refs.projectsDrawer.Open(row.id)" type="primary">{{ row.number }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 项目名称 -->
                    <vxe-column min-width="250" field="name" title="项目名称" sortable>
                        <template #default="{ row }">
                            <!-- 状态判断 -->
                            <el-tag v-if="row.status == 1" size="mini" effect="dark">进行中</el-tag>
                            <el-tag v-if="row.status == 2" size="mini" effect="dark" type="warning">已暂停</el-tag>
                            <el-tag v-if="row.status == 4" size="mini" effect="dark" type="info">已关闭</el-tag>
                            <el-tag v-if="row.status == 5" size="mini" effect="dark" type="success">待验收</el-tag>
                            <!-- 延期判断 -->
                            <el-tag v-if="row.is_overdue" size="mini" effect="dark" type="danger" class="w-margin-l-5">已延期</el-tag>
                            <el-tag v-if="row.contract_id" size="mini" effect="plain" class="w-margin-l-5">合</el-tag>
                            <!-- 项目名称 -->
                            {{ row.name }}
                        </template>
                    </vxe-column>

                    <!-- 项目进度 -->
                    <vxe-column min-width="100" title="项目进度">
                        <template #default="{ row }">
                            <div :style="{ display: 'flex', alignItems: 'center' }">
                                <el-progress :color="progressColors" :show-text="false" type="circle" :width="20" :percentage="Math.floor(row.statistics.task.close/row.statistics.task.count*100) || 0" :stroke-width="2"></el-progress>
                                <el-link :style="{ marginLeft: '5px', justifyContent: 'flex-start' }" :underline="false">
                                    {{ Math.floor(row.statistics.task.close/row.statistics.task.count*100) || 0 }} %
                                </el-link>
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 任务 -->
                    <vxe-column min-width="80" field="statistics.task.count" title="已关闭任务">
                        <template #default="{ row }">
                            <el-link :underline="false" icon="el-icon-tickets">
                                <b>{{ row.statistics.task.close }} / </b>
                                <span :style="{ color: '#9e9e9e' }">{{ row.statistics.task.count }}</span>
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 成员 -->
                    <vxe-column width="80" field="statistics.team" title="成员">
                        <template #default="{ row }">
                            <el-link icon="el-icon-user">{{ row.statistics.team }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 项目经理 -->
                    <vxe-column width="120" field="leader_object.user.name" title="项目经理">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;" v-if="row.leader_object?.user">
                                <el-avatar style="margin-right: 5px;" :size="26" v-if="row.leader_object?.user.avatar" :src="row.leader_object?.user.avatar" />
                                <el-avatar style="margin-right: 5px;" :size="26" v-else> {{ row.leader_object?.user.name[0] }} </el-avatar>
                                {{  row.leader_object?.user.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 项目销售 -->
                    <vxe-column width="120" field="sale_object.user.name" title="项目销售">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;" v-if="row.sale_object?.user">
                                <el-avatar style="margin-right: 5px;" :size="26" v-if="row.sale_object?.user.avatar" :src="row.sale_object?.user.avatar" />
                                <el-avatar style="margin-right: 5px;" :size="26" v-else> {{ row.sale_object?.user.name[0] }} </el-avatar>
                                {{  row.sale_object?.user.name }}
                            </div>
                        </template>
                    </vxe-column>
                    
                    <!-- 截止日期 -->
                    <vxe-column width="120" field="start_date[1]" title="截止日期">
                        <template #default="{ row }">
                            <el-link icon="el-icon-time">{{ row.start_date[1] }}</el-link>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>

        </uw-table>

        <!-- 组件 -->
        <projects-create ref="projectsCreate" @success="TableSearch()" />
        <pm-projects-drawer ref="projectsDrawer" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {

            loading: false,

            progressColors: [
                {color: '#ff1100', percentage: 20},
                {color: '#ff3d00', percentage: 40},
                {color: '#ff9800', percentage: 60},
                {color: '#ffbf00', percentage: 80},
                {color: '#89ff00', percentage: 90},
                {color: '#00b0ff', percentage: 99},
                {color: '#0027ff', percentage: 100}
            ],

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                role: null,
                is_overdue: null,
                status: null,
                number: null,
                name: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/my/projects',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['leader_object', 'sale_object', 'statistics'],
                    hidden: ['describe']

                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },
        

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },
    }
}
</script>